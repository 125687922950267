import React, { useEffect, useState } from 'react';
import { db } from './firebase'; // Importation de la base de données Firebase
import { collection, onSnapshot } from 'firebase/firestore'; // Firebase Firestore pour la collection et l'écoute des snapshots en temps réel
import { Modal, Typography, Box, List, ListItem, ListItemText, Button } from '@mui/material'; // Importation des composants Material-UI
import CloseIcon from '@mui/icons-material/Close'; // Icône de fermeture
import dayjs from 'dayjs'; // Librairie pour manipuler les dates
import 'dayjs/locale/fr'; // Support de la localisation française pour dayjs
import './App.css'; // Importation du fichier CSS personnalisé
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'; // Importation des fonctions d'authentification Firebase
import 'bootstrap/dist/css/bootstrap.min.css'; // Importation du fichier CSS de Bootstrap


const App = () => {
  // Variables d'état pour gérer les données et l'affichage
  const [monitoringData, setMonitoringData] = useState([]); // Stocke les données de surveillance
  const [selectedService, setSelectedService] = useState(null); // Service sélectionné pour la modale
  const [open, setOpen] = useState(false); // Gère l'ouverture/fermeture de la modale
  const [isNightMode, setIsNightMode] = useState(false); // État pour basculer entre le mode jour et nuit
  const [user, setUser] = useState(null); // Utilisateur connecté
  const [email, setEmail] = useState(''); // Champ de l'email
  const [password, setPassword] = useState(''); // Champ du mot de passe
  const [error, setError] = useState(''); // Erreur d'authentification
  const [autoSwitch, setAutoSwitch] = useState(true); // Nouvel état pour le basculement automatique


  const auth = getAuth(); // Authentification Firebase

  // Ouvre la modale pour un service sélectionné
  const handleOpen = (service) => {
    setSelectedService(service); // Définit le service sélectionné
    setOpen(true); // Ouvre la modale
  };

  // Ferme la modale
  const handleClose = () => {
    setOpen(false); // Ferme la modale
  };

  // Fonction pour basculer entre les modes jour et nuit
  const toggleMode = () => {
    setIsNightMode((prevMode) => !prevMode);
    setAutoSwitch(false); // Désactive le changement automatique après une interaction manuelle
  };

    // Basculement automatique selon l'heure
    useEffect(() => {
      if (!autoSwitch) return; // Si l'utilisateur a modifié manuellement, ne pas basculer automatiquement
  
      const updateModeBasedOnTime = () => {
        const currentHour = dayjs().hour();
        if (currentHour >= 20 || currentHour < 6) {
          setIsNightMode(true); // Mode nuit à partir de 20h
        } else {
          setIsNightMode(false); // Mode jour à partir de 6h
        }
      };
  
      updateModeBasedOnTime(); // Initialisation
  
      const interval = setInterval(updateModeBasedOnTime, 60000); // Vérification toutes les minutes
  
      return () => clearInterval(interval); // Nettoyage de l'intervalle
    }, [autoSwitch]);

  // useEffect pour récupérer les données de Firebase en temps réel
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user); // Met à jour l'état de l'utilisateur actuel
    });

    const unsubscribe = onSnapshot(collection(db, 'MonitoringStatus'), (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        const timestamp = docData.timestamp ? docData.timestamp.toDate() : new Date(); // Conversion du timestamp en date
        return {
          id: doc.id,
          ...docData,
          ServiceTag: docData.ServiceTag.replace(/_/g, ' '), // Remplacement des underscores par des espaces
          timestamp,
        };
      });

      const sortedData = data.sort((a, b) => {
        const now = dayjs();
        const aOlderThan5Minutes = now.diff(dayjs(a.Timestamp), 'minute') > 5;
        const bOlderThan5Minutes = now.diff(dayjs(b.Timestamp), 'minute') > 5;

        if (a.Services.Elisath === false && b.Services.Elisath === true) return -1;
        if (a.Services.Elisath === true && b.Services.Elisath === false) return 1;
        if (aOlderThan5Minutes && !bOlderThan5Minutes) return -1;
        if (!aOlderThan5Minutes && bOlderThan5Minutes) return 1;

        return 0;
      });

      const updatedData = sortedData.map((item) => ({
        ...item,
        ServiceTag: item.Services.Elisath === false ? `🚨 ${item.ServiceTag}` : item.ServiceTag,
      }));

      setMonitoringData(updatedData); // Mise à jour des données dans l'état
    });

    // Nettoyage des abonnements
    return () => {
      unsubscribeAuth(); // Désinscription de l'écouteur d'authentification
      unsubscribe(); // Désinscription de l'écouteur de snapshot
    };
  }, [auth]);

  const getBackgroundColor = (timestamp) => {
    const now = dayjs();
    const lastUpdated = dayjs(timestamp);
    const diffInMinutes = now.diff(lastUpdated, 'minute');

    if (diffInMinutes > 5) return isNightMode ? 'darkred' : '#f9e0e4';
    return isNightMode ? 'darkgreen' : '#a5ffb1';
  };

  // Connexion avec l'email et mot de passe
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        setError('');
      })
      .catch((error) => {
        /* mettre le message d'erreur sur deux ligne */
        setError('Email ou mot de passe incorrect. Contacter le support technique pour plus d\'informations.');  
        console.log('Essaye encore 🖕🖕🖕🖕');
      });
  };

  // Déconnexion de l'utilisateur
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setEmail('');
        setPassword('');
        setError('');
      })
      .catch((error) => {
        console.error('Erreur lors de la déconnexion:', error);
      });
  };

  if (!user) {
    return (
      <div className="login-container">
        <h2><span>🚀</span> Monitor By Mika</h2>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
        </div>
        {error && <p className="error-text">{error}</p>}
        <Button variant="contained" color="primary" onClick={handleLogin}>
        <strong>Allez c'est parti mon kiki</strong>
        </Button>
      </div>
    );
  }

  return (
    <div className={`app-container ${isNightMode ? 'night-mode' : 'day-mode'}`}>
    {/* Header avec bascule jour/nuit */}
    <Box className="header">
      Surveillance des Services & IP
      <div className="legend">
        <div className="legend-item">
          <span className="legend-circle green-circle"></span>
          <span className="legend-text">Opérationnel</span>
        </div>
        <div className="legend-item">
          <span className="legend-circle red-circle"></span>
          <span className="legend-text">Hors service</span>
        </div>
        {/* Bouton pour basculer entre les modes */}
        <div>
          <Button
            variant="contained"
            onClick={toggleMode}
            sx={{
              marginLeft: '20px',
              backgroundColor: !isNightMode ? 'info' : '#282C34',
              color: !isNightMode ? '#000' : '#fff'
            }}
          >
            {isNightMode ? 'Jour ☀️' : 'Nuit 🌙'}
          </Button>
        </div>
      </div>
        {/* inserer le bouton de deconnexion en dessous */}
        <div>
          <Button
            variant="contained"
            onClick={handleLogout}
            sx={{
              marginLeft: '20px',
              backgroundColor: !isNightMode ? 'info' : '#282C34',
              color: !isNightMode ? '#000' : '#fff'
            }}
          >
          🔐 Hasta la vista, baby
          </Button>
          </div>
    </Box>

    {/* Grille des services */}
    <Box
      className="main-content"
      sx={{
        backgroundColor: isNightMode ? '#282C34' : '',
      }}
    >
      <Box className="service-grid">
        {monitoringData.map((doc) => (
          <Box
            key={doc.ServiceTag}
            className="service-box"
            sx={{
              backgroundColor: getBackgroundColor(doc.Timestamp), // Utilisation de la fonction pour la couleur
              color: isNightMode ? '#fff' : '#000'
            }}
          >
            <Typography variant="h6">{doc.ServiceTag}</Typography>
            <Typography
              variant="body2"
              className={
                dayjs().diff(dayjs(doc.Timestamp), 'minute') > 5
                  ? isNightMode
                    ? 'text-center'
                    : 'red-text text-center'
                  : isNightMode
                    ? 'text-white text-center'
                    : 'text-center'
              }
            >
              Dernière mise à jour le {dayjs(doc.Timestamp).locale('fr').format('D MMMM YYYY [à] HH:mm:ss')}
            </Typography>

            <Box className="service-buttons">
              <span
                className={`badge rounded-pill ${
                  doc.Services.Elisath === undefined
                    ? 'text-bg-primary'
                    : doc.Services.Elisath
                    ? 'text-bg-success'
                    : 'text-bg-danger'
                }`}
                onClick={() => handleOpen(doc)}
              >
                <span>Elisath</span>
              </span>

              <span
                className={`badge rounded-pill ${
                  doc.Services.MariaDB === undefined
                    ? 'text-bg-primary'
                    : doc.Services.MariaDB
                    ? 'text-bg-success'
                    : 'text-bg-danger'
                }`}
                onClick={() => handleOpen(doc)}
              >
                {doc.Services.MariaDB && <span>MariaDB</span>}
              </span>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Modale pour afficher les détails */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        onClick={(e) => {
          if (e.target === e.currentTarget) handleClose();
        }}
      >
        <Box
          className="modal-content"
          sx={{
            borderRadius: '16px',
            borderStyle: 'double',
            boxShadow: 24,
            backgroundColor: isNightMode ? '#444' : '#d8d8d8',
            color: isNightMode ? '#fff' : '#000',
          }}
        >
          <button className="modal-close-button" onClick={handleClose}>
            <CloseIcon />
          </button>

          {selectedService && (
            <>
              <Typography variant="h6" gutterBottom>
                Statut des IPs - {selectedService.ServiceTag}
              </Typography>
              <List sx={{ padding: 0 }}>
                {/* Trier les IPs par ordre croissant avant de les mapper */}
                {Object.entries(selectedService.IPs)
                  .sort(([ipA], [ipB]) => ipA.localeCompare(ipB)) /* Trier les IP par ordre croissant */
                  .map(([ip, { reachable, description }]) => (
                    <ListItem key={ip} sx={{ padding: '4px 0' }}>
                      <ListItemText
                        primary={
                          <>
                            {/* Appliquer la couleur en fonction du mode jour/nuit et du statut reachable */}
                            <span
                              style={{
                                color: isNightMode
                                  ? reachable
                                    ? '#00ff72'
                                    : '#f97f91'
                                  : reachable
                                  ? 'green'
                                  : 'red',
                              }}
                            >
                              {reachable ? '🛜 ' : '⛔ '} {ip} - {description}
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </>
          )}
        </Box>
      </Modal>
    </Box>

    {/* Footer */}
    <Box className="footer">Made with ♥️ by Mika</Box>
  </div>
  );
};

export default App;
