// firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'; // Importation de l'authentification

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAhYDE-mQWuDvVRMTwlw1Im_vtTOdw35x8",
  authDomain: "monitor-4c028.firebaseapp.com",
  projectId: "monitor-4c028",
  storageBucket: "monitor-4c028.appspot.com",
  messagingSenderId: "675007819644",
  appId: "1:675007819644:web:f40c35e2bbcee31e297ef9"
};

// Initialisation Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Définir la persistance pour que l'utilisateur reste connecté
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // La persistance est définie
  })
  .catch((error) => {
    console.error("Erreur lors de la définition de la persistance : ", error);
  });

export { db, auth, collection, onSnapshot };